<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <section class="captures-area">
    <div class="vx-con--tabs">
      <div class="vx-con-tabs-nav flex sm:justify-between sm:items-center mb-3">
        <ul class="flex">
          <li class="relative">
            <vs-button
              @click="$router.push('/captures').catch(() => [])"
              type="line"
              icon-pack="feather"
              icon="icon-list"
              :class="{ 'vs-button-line--active': $route.name === 'captures' }"
            >
              {{ !isSmallerScreen ? 'List' : '' }}
            </vs-button>
          </li>
          <li class="relative">
            <vs-button
              @click="$router.push('/captures/domains').catch(() => [])"
              type="line"
              icon-pack="feather"
              icon="icon-link-2"
              :class="{ 'vs-button-line--active': $route.name === 'captures-domains' }"
            >
              {{ !isSmallerScreen ? 'By domain' : '' }}
            </vs-button>
          </li>
          <li class="relative">
            <vs-button
              @click="$router.push('/captures/tags').catch(() => [])"
              type="line"
              icon-pack="feather"
              icon="icon-tag"
              :class="{ 'vs-button-line--active': $route.name === 'captures-tags' }"
            >
              {{ !isSmallerScreen ? 'By tags' : '' }}
            </vs-button>
          </li>
        </ul>
      </div>
      <div class="vx-con--tabs-block">
        <router-view />
      </div>
    </div>
    <!-- <vs-tabs v-show="hasCaptures || $route.query.datecaptured" position="top">
      <vs-tab
        class="px-0"
        icon-pack="feather"
        icon="icon-list"
        :label="!isSmallerScreen ? 'List' : ''"
      >
        <List />
      </vs-tab>
      <vs-tab
        class="px-0"
        icon-pack="feather"
        icon="icon-link-2"
        :label="!isSmallerScreen ? 'By domain' : ''"
      >
        <Domains />
      </vs-tab>
      <vs-tab
        class="px-0"
        icon-pack="feather"
        icon="icon-tag"
        :label="!isSmallerScreen ? 'By Tags' : ''"
      >
        <Tags />
      </vs-tab>
    </vs-tabs> -->
  </section>
</template>

<script>
import { mapState } from 'vuex';
// import List from './List';
// import Domains from './Domains';
// import Tags from './Tags';

export default {
  components: {
    // List,
    // Domains,
    // Tags
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('captures', ['list', 'hasCaptures']),
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
    empty() {
      try {
        return this.list.items.length === 0;
      } catch (error) {
        return true;
      }
    }
  },
  methods: {},
  mounted() {}
};
</script>
